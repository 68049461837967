.individual-visualization {
    @include flexCenter(column);
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.08);
    margin-top: 30px;
    margin-bottom: 16px;
    padding: 20px;
    padding-left: 48px;
    min-height: 20vh;
    min-width: 40vh;
    color: white;

    .row {
        width: 100%;
        @include flexCenter(row);
    }

    .column {
        flex: 1;
        @include flexCenter(column);
        height: 100%;
        justify-content: space-between;
        align-items: flex-start;

        h2 {
            font-size: 2em;
            font-weight: 600;
            overflow-y: hidden;
            min-height: 85px;
        }

        .number {
            font-size: 2em;
            font-weight: 600;
        }
    }

}