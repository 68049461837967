// Background gradient
$backGradientTo: white;
$backGradientFrom: #66a8ff;
$fallbackLinearFrom: #d6e6ff;
$fallbackLinearTo: #dbd6ff;

// Text gradient 1
$backColor1: #667eea;
$backColor2: #774aa3;

// Text gradient 2
$backColor3: #8af7fe;
$backColor4: #66a8ff;
