.input-container {
  @include flexCenter(column);

  h3 {
    color: white;
    max-width: 80vw;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .inputfile {
    // Hides the ugly default input file so only the label shows
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    + label {
      padding: 20px;
      border-radius: 20px;
      font-size: 1.25em;
      font-weight: bold;
      color: white;
      background-color: black;
      background-image: linear-gradient(140deg, $backColor1, $backColor2);
      display: inline-block;
      cursor: pointer;
    }

    &:focus + label,
    + label:hover {
      color: black;
      background-image: linear-gradient(140deg, $backColor3, $backColor4);
      background-color: $backColor3;
    }
  }

  .go-button {
    padding: 20px;
    border: 0px;
    border-radius: 20px;
    font-size: 1.25em;
    font-weight: bold;
    color: white;
    background-color: black;
    background-image: linear-gradient(140deg, $backColor1, $backColor2);
    cursor: pointer;

    &:focus {
      outline: none !important;
    }

    &:hover {
      color: black;
      background-image: linear-gradient(140deg, $backColor3, $backColor4);
      background-color: $backColor3;
    }
  }
}
