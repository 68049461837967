.viz-container {
  @include flexCenter(column);
  color: white;
  width: 100vw;
  padding-top: 4vh;
  padding-bottom: 4vh;

  h1 {
    padding: 20px;
    margin: 0;
    font-size: 4vw;

    // Fallback to white
    color: white;

    .gradient-text {
      // Fallback color if gradient doesnt load
      background-color: white;
      // Gradient to overlay on text span
      background-image: linear-gradient(140deg, $backColor1, $backColor2);

      // Tricks to clip the overlay onto the text
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
  }

  .bold {
    font-weight: 600;
    text-align: center;
  }

  h2 {
    margin: 0;
  }

  .item-container {
    @include flexCenter(column);

    .comment {
      margin: 0;
      margin-top: 0px;
      margin-bottom: 12px;
      max-width: 400px;
      text-align: center;
    }
  }

  .description {
    color: grey;
  }

  .stat-item {
    @include flexCenter(column);
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.08);
    margin-top: 30px;
    margin-bottom: 16px;
    padding: 20px;
    min-height: 10vh;
    min-width: 20vh;

    text {
      color: white;
    }

    text[aria-describedby]{
      color: white !important;
    }
  }

  .flex-row {
    @include flexCenter(row);
    flex-wrap: wrap;
    width: 100vw;
  }

  // Temporary (uncomment for debugging)
  canvas {
    // border: 1px dotted red;
  }

  .chart-container {
    position: relative;
    margin: auto;
    height: 30vh;
    width: 40vw;

    @media only screen and (max-width: 700px) {
      height: 60vh;
      width: 80vw;
    }

    @media only screen and (max-height: 600px) {
      height: 90vh;
      width: 80vw;
    }
  }

  .horizontal {
    padding-right: 20px;
  }

  .bigger {
    height: 600px;
  }

  .hourly {
    width: 90vw;
  }

  .share-button {
    margin: 20px;
    padding: 20px;
    border: 0px;
    border-radius: 20px;
    font-size: 1.25em;
    font-weight: bold;
    color: white;
    background-color: black;
    background-image: linear-gradient(140deg, $backColor1, $backColor2);
    cursor: pointer;

    &:focus {
      outline: none !important;
    }

    &:hover {
      color: black;
      background-image: linear-gradient(140deg, $backColor3, $backColor4);
      background-color: $backColor3;
    }
  }
}
